.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .not-found-container h1 {
    font-size: 10rem;
    font-weight: 800;
    margin: 0;
    color: #333;
  }
  
  .not-found-container h2 {
    font-size: 4rem;
    font-weight: 500;
    margin: 1rem 0;
    color: #666;
  }
  
  .not-found-container p {
    font-size: 1.6rem;
    font-weight: 300;
    margin: 1rem 0;
    color: #999;
  }
  
  .not-found-container a {
    font-size: 1.4rem;
    font-weight: 500;
    text-decoration: none;
    color: #333;
    border: 2px solid #333;
    padding: 1rem 2rem;
    border-radius: 3px;
    transition: all 0.3s ease;
  }
  
  .not-found-container a:hover {
    background-color: #333;
    color: #fff;
  }